<template>
  <div class="articleView">
    <div v-if="!isTimeOver && !isNotFound">
      <div class="articleMain mx-auto">
        <h1 class="articleTtl" itemprop="headline">{{ articleTitle }}</h1>

        <div class="articleBody">
          <figure class="articleImage">
            <p class="echoesAttentionOnVideo">
              この記事は以下の動画を基に、動画投稿者の承諾を得た上で、AIライターが執筆しております
            </p>
            <iframe :src="youtubeEmbedUrl" title="YouTube video player" width="650" height="366" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen>
            </iframe>
          </figure>
      
          <div class="echoesArticleText">
          {{ articleBody }}
          </div>
      
          <div v-if="isvideoSegments">
            <div class="echoesVideoContents">
              <h2>YouTubeの動画内容</h2>
              <div class="echoesVideoContentsWrap">
                <div v-for="(segment, index) in videoSegments" :key="index">
                  <div class="echoesVideoContent">
                    <a :href="segment.targeturl" target="_blank" rel="noopener">
                      <span>{{ segment.timestamp }}</span>
                      <p>{{ segment.title }}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isrelatedVideo">
            <div class="echoesConnections">
              <h2 class="mainTtl">関連動画（外部リンク）</h2>
              <div class="mainBody">
                <ul class="relatedEchoesList">
                  <div v-for="(relatedVideo, index) in relatedVideoFeeds" :key="index">
                    <a :href="relatedVideo.videoUrl" class="relatedEchoes" target="_blank" rel="nofollow">
                      <div class="thumbnailWrapper">
                        <img :src="relatedVideo.thumbnailUrl" class="thumbnailEchoes">
                        <img class="playIcon" src="@/assets/icon_play.png">
                      </div>
                      <div class="echoesBody">
                        <p class="echoesSubject">{{ relatedVideo.videoTitle }}</p>
                        <p class="echoesName">{{ relatedVideo.channelTitle }}</p>
                        <div class="domainInfo">
                          <img src="http://www.google.com/s2/favicons?domain=https://www.youtube.com/watch?v=x7Pz5FO3fks">
                          <p class="domainName">youtube.com</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <div class="echoesChannelInfo">
            <h2><span>チャンネル情報</span></h2>
            <div v-for="(ch, index) in channel" :key="index">
              <div class="echoesChannelProf">
                <div v-if="ischannelFlg">
                  <a :href="channelLink">
                    <img :src="ch.channel_icon" alt="ch.account_name">
                    <div>
                      <h3>{{ ch.account_name }}</h3>
                      <span>YouTube チャンネル登録者数 {{ ch.num_subscribes }} 人</span>
                      <span>{{ ch.num_uploadvideos }} 本の動画</span>
                    </div>
                  </a>
                </div>
                <div v-else>
                  <a>
                    <img :src="ch.channel_icon" alt="ch.account_name">
                    <div>
                      <h3>{{ ch.account_name }}</h3>
                      <span>YouTube チャンネル登録者数 {{ ch.num_subscribes }} 人</span>
                      <span>{{ ch.num_uploadvideos }} 本の動画</span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="echoesChannelIntro">
                {{ ch.channel_description }}
              </div>

              <div class="echoesChannelLink">
                <a :href="ch.channel_url" target="_blank" rel="noopener">
                {{ ch.channel_url }}
                </a>
                <span>YouTube</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 初回表示時のポップアップウィンドウ -->
        <div v-if="isOpen" class="popup">
          <div align="center">
            <span class="dli-exclamation-circle"><span></span></span>
          </div>
          <h2>プレビュー画面の共有について</h2>
          <h3>
          この画面はURLでの共有ができません。外部へ共有する際は、スクリーンキャプチャなどをご使用ください。
          </h3>
          <div align="center">
            <button @click="open" class="openbutton">詳しく</button>
            <button @click="close" class="closebutton">閉じる</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isTimeOver && !isNotFound">
      <div class="timeover mx-auto">
        <h1>プレビューの有効期限が切れました</h1>
        <div class="timeoverBody">
          一定時間操作が行われなかったため、プレビューのセッションが終了しました。再度プレビューを確認する場合は、記事編集画面に戻って「プレビューを見る」をクリックしてください。
        </div>
        <div class="timeoverLink">
          <a :href="returnLink">トップページに戻る</a>
        </div>
        <div class="timeoverBottom">
          <p>このページは5秒後、自動的にトップへ戻ります。</p>
        </div>
      </div>
    </div>

    <div v-if="isNotFound">
        <NotFound />
    </div>

  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { BASE_URL_DEV } from '@/plugins/constants';

export default defineComponent({
    components: {
        NotFound
    },
    setup() {
        const store = useStore();
        const { currentRoute } = useRouter();
        const route = currentRoute.value;

        // 画面表示切り替え
        let isOpen = ref(false);
        let isvideoSegments = ref(false);
        let isrelatedVideo = ref(false);
        let ischannelFlg = ref(true);
        let isTimeOver = ref(false);
        let isNotFound = ref(false);

        // 画面項目
        let articleId = ref(null);
        let paramArticleId = ref(null);
        let articleTitle = ref('');
        let articleBody = ref('');
        let videoSegments = ref([]);
        let youtubeEmbedUrl = ref('');
        let youtubePreviewUrl = ref('');
        let channel = ref([]);
        let channelLink = ref('');
        let relatedVideoFeeds = ref([]);
        let previewData = ref({});
        let returnLink = ref('/login');

        // ポップアップ画面 「詳しく」「閉じる」打鍵時
        const open = () => {
            const url = 'https://note.com/livedoor_echoes/n/ne30a27ead275';
            window.open(url, '_blank');
        };
        const close = () => {
            isOpen.value = false;
        };

        const convertTimestampToSeconds = (timestamp) => {
            const parts = timestamp.split(':').reverse();
            let seconds = 0;
            for (let i = 0; i < parts.length; i++) {
                seconds += parseInt(parts[i], 10) * Math.pow(60, i);
            }
            return seconds;
        };

        onMounted(() => {
            articleId.value = route.params.id;
            if(!localStorage.getItem('disp_popup')) {
              localStorage.setItem('disp_popup', 'on');
              isOpen.value = true; // ポップアップ画面初期設定    
            }
            previewData.value = JSON.parse(store.state.previewData);
            if (previewData.value != null) {
                paramArticleId.value = previewData.value.articleId;
                articleTitle.value = previewData.value.articleTitle;
                articleBody.value = previewData.value.articleBody;
                youtubeEmbedUrl.value = previewData.value.youtubeEmbedUrl;
                youtubePreviewUrl.value = previewData.value.youtubePreviewUrl;
                // 記事IDのチェック(URLの記事IDとストア上のIDとを比較)
                if (articleId.value !== paramArticleId.value) {
                    isNotFound.value = true;
                }
                // YouTube動画の値、表示を調整
                if(previewData.value.videoSegments.length > 0) {
                    isvideoSegments.value = true;
                    for (let i = 0; i < previewData.value.videoSegments.length; i++) {
                        var item = {
                            title: previewData.value.videoSegments[i].title,
                            timestamp: previewData.value.videoSegments[i].timestamp,
                            targeturl: youtubePreviewUrl.value + "&t=" + convertTimestampToSeconds(previewData.value.videoSegments[i].timestamp + "s")
                        }
                        videoSegments.value.push(item);
                    }
                }
                // 関連動画の表示を調整
               if(previewData.value.relatedVideoFeeds.length > 0) {
                   isrelatedVideo.value = true;
                   relatedVideoFeeds.value = previewData.value.relatedVideoFeeds;
               }
               // チャンネル情報の調整
               channel.value = previewData.value.channel;
               let num = channel.value.value.num_subscribes;
               if(num.toString.length > 4){
                   let num1 = String(Number(num) / 100);
                   let num2 = num1.slice(0, num1.length -2);
                   let num3 = num1.slice(num1.length -2, num1.length);
                   num = num2 + '.' + num3 + '万';
                   channel.value.num_subscribes = num;
               }
               const baseUrl = window.location.origin;
               if(baseUrl === BASE_URL_DEV){
                   channelLink.value = process.env.VUE_APP_CHANNEL_LINK_DEV + store.state.user_id + '/';
               } else {
                   channelLink.value = process.env.VUE_APP_CHANNEL_LINK + store.state.user_id + '/';
               }
               ischannelFlg.value = previewData.value.channelFlg;
            }
            // ログイン状態確認
            const isAuthenticated = store.state.isAuthenticated;
            if (!isAuthenticated) {
                isTimeOver.value = true;
                setTimeout(() => {
                    window.location.href = "/login";
                }, 5000);
            }
        });

        return {
             articleId,
             paramArticleId,
             articleTitle,
             articleBody,
             videoSegments,
             youtubeEmbedUrl,
             youtubePreviewUrl,
             channel,
             channelLink,
             relatedVideoFeeds,
             previewData,
             isOpen,
             isvideoSegments,
             isrelatedVideo,
             ischannelFlg,
             isTimeOver,
             isNotFound,
             returnLink,
             open,
             close
        }
    }
})
</script>

<style scoped>
/* プレビューレイアウト */
.articleView {
  background-color: F9FAFB;
}
.articleMain {
  background-color: white;
  padding: 16px 15px;
  max-width: 700px;
  min-width: 375px;
  overflow-x: scroll;
}
.echoes#content {
  background-color: #fff;
}
/* 記事タイトル */
.articleTtl {
  font-size: 28px;
  color: #000;
  line-height: 36px;
  margin-bottom: 22px;
  font-weight: normal;
}
/* 記事本文 */
.articleBody {
	line-height: 1.9;
	font-size: 16px;
	overflow: hidden;
}
.articleBody img {
	max-width: 620px;
}
.articleBody p {
	margin: 0 0 20px;
}
.articleBody em {
	font-weight: bold;
	font-style: normal;
}
.articleBody h2 {
	font-size: 16px;
}
/* TOP動画 */
.articleBody .echoesAttentionOnVideo {
  color: #fff;
  background-color: #374151;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  line-height: 16px;
}
.articleBody iframe{
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 16px;
}
.echoesArticleText {
  color: #374151;
  margin-bottom: 24px;
  white-space: pre-wrap;
}
.articleImage {
  float: none;
  margin-bottom: 16px;
  margin-left: 0;
  line-height: 1.4;
}

/* YouTubeの動画内容 */
.echoesVideoContents {
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 30px;
}
.echoesVideoContents h2 {
  font-weight: 400;
  line-height: 24px;
  color: #374151;
}
.echoesVideoContentsWrap .echoesVideoContent {
  padding: 4px 0;
  margin-top: 8px;
}
.echoesVideoContentsWrap .echoesVideoContent a {
  font-size: 14px;
  color: #374151;
  display: flex;
}
.echoesVideoContentsWrap .echoesVideoContent a:hover {
  text-decoration: none;
}
.echoesVideoContentsWrap .echoesVideoContent span {
  font-size: 12px;
  background-color: #EFF6FF;
  color: #2355A5;
  line-height: 28px;
  padding: 0 4px;
  border-radius: 4px;
  flex-shrink: 0;
  height: 28px;
}
.echoesVideoContentsWrap .echoesVideoContent p {
  margin: 0;
  margin-left: 10px;
  margin-top: 5px;
  line-height: 1.231;
}

/* 関連動画 */
.echoesConnections {
  padding: 16px 0;
}
.echoesConnections p,
.echoesConnections a {
  line-height: 1.231;
}
.echoesConnections h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #374151;
  margin-bottom: 8px;
}

.echoesConnections + .articleShareBtn {
  justify-content: flex-end;
  margin-top: 16px;
}

/* チャンネル情報 */
.echoesChannelInfo {
  padding: 16px 0 0;
}
.echoesChannelInfo p {
  margin: 0;
}
.echoesChannelInfo h2 {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: #374151;
  margin-bottom: 20px;
  z-index: 0;
}
.echoesChannelInfo h2::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #9CA3AF;
  z-index: -1;
}
.echoesChannelInfo h2 span {
  background-color: #fff;
  padding-right: 8px;
}

.echoesChannelProf {
  margin-bottom: 8px;
}

.echoesChannelProf a {
  display: flex;
}

.echoesChannelProf img {
  width: 56px;
  height: 56px;
  border-radius: 9999px;
  border: solid 1px #E6E7EB;
}

.echoesChannelProf div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.echoesChannelProf div h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 5px;
  color: #374151;
}

.echoesChannelProf div span {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6B7280;
  margin-bottom: 4px;
}

.echoesChannelIntro {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #374151;
  margin-bottom: 16px;
}
.echoesChannelLink {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6B7280;
  padding-bottom: 20px;
  border-bottom: 1px solid #9CA3AF;
}
.echoesChannelLink a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #2355A5;
  margin-bottom: 4px;
}
.echoesChannelLink a::before {
  content: "";
  display: block;
  width: 18px;
  min-width: 18px;
  height: 16px;
  background: url('@/assets/icon_youtube.png');    
  background-size: 18px;
  margin-right: 5px;
}
.echoesChannelLink span {
  margin-left: 5px;
}
.noBorder .echoesChannelLink {
  border: none;
}

/* common.css 内容 */
/* title */
.mainTtl {
	font-size: 14px;
	color: #000;
	margin-bottom: 16px;
	font-weight: bold;
}
.mainTtl a {
	color: #2d2d2d;
}
.relatedEchoesList a {
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  padding: 8px 0;
}
.relatedEchoesList .relatedEchoes .echoesBody {
  margin-left: 10px;
  color: #374151;
}
.thumbnailWrapper {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 90px;
}
.thumbnailEchoes {
  height: 90px;
  width: 160px;
}
.articleBody .echoesBody p.echoesSubject, 
.articleBody .echoesBody p.echoesName {
  margin-bottom: 4px;
}
.echoesBody .echoesSubject {
  font-weight: bold;
}
.echoesSubject {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: Hiragino Kaku Gothic ProN W6;
}
.echoesBody .echoesName {
  color: #6B7280;
}
.echoesName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-family: Hiragino Kaku Gothic ProN W3;
}
.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 37px;
}
.domainInfo {
  display: flex;
}
.domainInfo img {
  height: 15px;
  width: 15px;
}
.domainInfo .domainName {
  font-size: 12px;
  margin-left: 5px;
  font-family: Hiragino Kaku Gothic ProN W3;
}

/* ポップアップウィンドウのスタイル */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  gap: 16px;
  background-color: white;
  border: 2px solid #2563EB;
  width: 288px;
  height: 290px;
}
.popup h2 {
  width: 224px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 16px;
  line-height: 24px;
}
.popup h3 {
  width: 224px;
  height: 96px;
  font-size: 16px;
  margin-bottom: 10px;
}
.popup .openbutton {
  background-color: #2563EB;
  color: white;
  margin: 10px;
  padding: 8px 16px;
}
.popup .closebutton {
  background-color: #FFFFFF;
  margin: 10px;
  padding: 8px 16px;
  border: 1px solid #D1D5DB;
}
/* エクスクラメーションマーク(丸囲み)のスタイル */
.dli-exclamation-circle {
display: inline-block;
vertical-align: middle;
color: white;
background-color: blue;
line-height: 1;
position: relative;
width: 1em;
height: 1em;
border: 0.1em solid currentColor;
border-radius: 50%;
box-sizing: content-box;
}
.dli-exclamation-circle > span {
width: 0.15em;
height: 0.6em;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: auto;
}
.dli-exclamation-circle > span::before, .dli-exclamation-circle > span::after {
content: '';
display: block;
width: inherit;
background: currentColor;
}
.dli-exclamation-circle > span::before {
margin-bottom: 0.1em;
height: 0.35em;
border-radius: 0.025em;
}
.dli-exclamation-circle > span::after {
height: 0.15em;
border-radius: 50%;
}
.bg-white shadow {
display:none;
}
/* タイムオーバーウィンドウのスタイル */
.timeover {
background-color: white;
border-radius: 10px;
max-width: 640px;
min-width: 375px;
height: 600px;
padding: 40px;
}
.timeover h1 {
font-size: 28px;
text-align: center;
color: #000;
line-height: 36px;
margin-bottom: 22px;
font-weight: normal;
}
.timeoverBody {
overflow: visible;
padding: 0 20px 20px;
}
.timeover a {
text-align: center;
color: blue;
}
.timeoverLink{
text-align: center;
}
.timeoverBottom {
color: gray;
text-align: center;
overflow: visible;
padding: 20px;
}
/* pagination end */
</style>